import styles from './LoadingAnimation.module.scss'

const LoadingAnimation = ({ size = 80 }) => {
  const style = { '--loading-spinner-size': `${size}px` } as React.CSSProperties
  return (
    <div className={styles.container} style={style}>
      <div className={styles.spinner}></div>
    </div>
  )
}

export default LoadingAnimation
