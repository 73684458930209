import styles from './ShareButton.module.scss'
import useImageGenStore from '../../lib/imageGenStore'
import logInteraction from '../../lib/logInteraction'
import useAuth from '../../lib/useAuth'
export default function ShareButton({
  id,
  onClick,
}: {
  id: string
  onClick: () => void
}) {
  const { currentUser, showSignInModal } = useAuth()
  const imageGens = useImageGenStore((state) => state.imageGens)
  const imageGenShare = useImageGenStore((state) => state.share)
  const tooltips = useImageGenStore((state) => state.tooltips)
  const imageGen = imageGens[id]

  return (
    <button
      onClick={() => {
        if (!currentUser) {
          showSignInModal()
          return
        }
        imageGenShare() // Prevents tooltip showing again
        logInteraction({
          id: id,
          type: 'share',
          additionalData: imageGen.analytics,
        })
        onClick()
      }}
      className={styles.button}
      data-tooltip-id={'image-gen-tooltips'}
      data-tooltip-place={'top'}
      data-tooltip-content={'Share this image'}
      data-tooltip-hidden={!tooltips.showShare}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        width='24'
        height='24'
      >
        <path
          fill='#231F20'
          d='M16.861 14.933a2.881 2.881 0 0 0-2.067.88h-.039l-4.883-2.997c.066-.245.178-.665.18-.919a2.897 2.897 0 0 0-.18-.964l4.587-2.746c.33.618.73.847 1.386 1.09a2.86 2.86 0 0 0 3.537-1.382 2.87 2.87 0 0 0-.941-3.683 2.86 2.86 0 0 0-3.766.492 2.87 2.87 0 0 0-.703 1.907l.028.39-4.731 2.787a.805.805 0 0 0-.077.052 2.894 2.894 0 0 0-4.94 2.17 2.902 2.902 0 0 0 1.95 2.622c.552.19 1.147.21 1.709.055.562-.154.904-.31 1.28-.754l4.941 2.98a2.87 2.87 0 0 0-.16.913 2.896 2.896 0 0 0 1.784 2.672 2.885 2.885 0 0 0 3.148-.627 2.894 2.894 0 0 0-2.043-4.938Zm0-9.93a1.603 1.603 0 0 1 1.483.993 1.61 1.61 0 0 1-1.17 2.191 1.603 1.603 0 0 1-1.918-1.576 1.608 1.608 0 0 1 1.605-1.607Zm-9.698 8.501a1.603 1.603 0 0 1-1.574-1.92A1.608 1.608 0 0 1 6.85 10.32a1.603 1.603 0 0 1 1.918 1.576 1.608 1.608 0 0 1-1.605 1.607Zm9.698 5.929a1.604 1.604 0 0 1-1.574-1.92 1.608 1.608 0 0 1 1.26-1.264 1.603 1.603 0 0 1 1.919 1.577 1.609 1.609 0 0 1-1.605 1.607Z'
        />
      </svg>
    </button>
  )
}
