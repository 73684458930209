import styles from './SubscribeForm.module.scss'
import { useContext, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { ImageGenContext } from '../context/ImageGenContext'
import { genericEvent } from '../../lib/events'

function SubscribeForm() {
  const [subscribing, setSubscribing] = useState(false)
  const { setPermissions, setModalContent } = useContext(ImageGenContext)
  const queryClient = useQueryClient()

  const { isPending, error, data } = useQuery({
    queryKey: ['subscriptionPermissions'],
    queryFn: async () => {
      const response = await fetch('/api/v1/image_gen/fetch_permissions')
      const json = await response.json()
      if (response.ok && json.permissions) {
        setPermissions(json.permissions)
        setSubscribing(false)
        if (json.permissions.canGenerateImages) {
          // user has subscribed, close the modal.
          genericEvent({
            event: 'subscribeSuccessful',
            data: {},
          })
          setModalContent(null)
        }
      }
      return json
    },
  })

  if (subscribing) {
    if (isPending) {
      return <div className={styles.wrapper}>Loading...</div>
    }
    return (
      <div className={styles.wrapper}>
        Subscribing...
        <button
          onClick={(e) => {
            // Users shouldn't see this button because react-query should re-fetch this URL when
            // the browser window gets focus again. But if that auto thing doesn't work, this manual button will kick it off.
            queryClient.invalidateQueries({
              queryKey: ['subscriptionPermissions'],
            })
          }}
        >
          refresh
        </button>{' '}
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      please subscribe
      <a
        rel='noopener noreferrer'
        target='_blank'
        href='https://elements.envato.com/pricing?utm_source=labs&amp;utm_medium=referral&amp;utm_campaign=elements_labs_upsell'
        onClick={(e) => {
          genericEvent({
            event: 'subscribeClick',
            data: {},
          })
          setSubscribing(true)
        }}
      >
        Subscribe
      </a>
    </div>
  )
}

export default SubscribeForm
