import styles from './History.module.scss'
import ImageGenRequest from './ImageGenRequest'
import useImageGenStore from '../lib/imageGenStore'
import { useInfiniteQuery } from '@tanstack/react-query'
import React from 'react'

export default function History() {
  const addImageGens = useImageGenStore((state) => state.addImageGens)

  const fetchHistory = async ({ pageParam }) => {
    const res = await fetch('/api/v1/image_gen/history', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        since: pageParam,
      }),
    })
    const data = await res.json()

    if (data && data.length > 0) {
      data.map((imageGenRequest) => {
        if (imageGenRequest.images.length > 0) {
          addImageGens(imageGenRequest.images)
        }
      })
    }
    return data
  }

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status } =
    useInfiniteQuery({
      queryKey: ['imageGenHistory'],
      queryFn: fetchHistory,
      initialPageParam: null,
      gcTime: 0,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.length === 0) return null

        return lastPage[lastPage.length - 1].createdAt
      },
    })

  if (status === 'pending') {
    return <div></div>
  }
  if (status === 'error') {
    return <div>Error fetching history..</div>
  }

  return (
    <>
      {data.pages.map((imageGenRequests, i) => {
        return (
          <React.Fragment key={i}>
            {imageGenRequests.map((imageGenRequest) => {
              if (imageGenRequest.images.length > 0) {
                return (
                  <ImageGenRequest
                    key={imageGenRequest.id}
                    {...imageGenRequest}
                  />
                )
              }
            })}
          </React.Fragment>
        )
      })}
      {hasNextPage && (
        <button
          className={styles.loadMoreButton}
          onClick={() => {
            fetchNextPage()
          }}
          disabled={isFetchingNextPage}
        >
          {isFetchingNextPage ? 'Loading' : 'Load More'}
        </button>
      )}
    </>
  )
}
