import styles from './VoteButtons.module.scss'
import useImageGenStore from '../../lib/imageGenStore'
import logInteraction from '../../lib/logInteraction'
import useAuth from '../../lib/useAuth'

export default function VoteButtons({ id }: { id: string }) {
  const { currentUser, showSignInModal } = useAuth()
  const imageGens = useImageGenStore((state) => state.imageGens)
  const imageGenUpvote = useImageGenStore((state) => state.upvote)
  const imageGenDownvote = useImageGenStore((state) => state.downvote)
  const tooltips = useImageGenStore((state) => state.tooltips)
  const imageGen = imageGens[id]
  const vote = imageGen?.vote

  return (
    <>
      <div className={styles.voteButtonWrapper}>
        <button
          onClick={() => {
            if (!currentUser) {
              showSignInModal()
              return
            }
            imageGenUpvote(id)
            logInteraction({
              id: id,
              type: 'upvote',
              additionalData: imageGen.analytics,
            })
          }}
          className={styles.voteButton}
          data-set-vote={'upvote'}
          data-tooltip-id={'image-gen-tooltips'}
          data-tooltip-place={'top'}
          data-tooltip-html={
            'Upvote this image. <br/> The generator is improved based on your votes'
          }
          data-tooltip-hidden={!tooltips.showVoting}
        >
          {vote === 'upvote' ? (
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M18 21H8V8L15 1L16.25 2.25C16.3667 2.36667 16.4625 2.525 16.5375 2.725C16.6125 2.925 16.65 3.11667 16.65 3.3V3.65L15.55 8H21C21.5333 8 22 8.2 22.4 8.6C22.8 9 23 9.46667 23 10V12C23 12.1167 22.9875 12.2417 22.9625 12.375C22.9375 12.5083 22.9 12.6333 22.85 12.75L19.85 19.8C19.7 20.1333 19.45 20.4167 19.1 20.65C18.75 20.8833 18.3833 21 18 21ZM6 8V21H2V8H6Z'
                fill='#191919'
              />
            </svg>
          ) : (
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M17.7307 20.5H7.21153V8.50005L13.8461 1.90393L14.6923 2.75005C14.7961 2.8539 14.8823 2.9914 14.9509 3.16255C15.0195 3.3337 15.0538 3.49492 15.0538 3.64621V3.90005L13.9923 8.50005H20.6923C21.1679 8.50005 21.5881 8.68243 21.9528 9.04718C22.3176 9.41191 22.5 9.83211 22.5 10.3078V11.9231C22.5 12.027 22.4865 12.1391 22.4596 12.2596C22.4326 12.3801 22.4025 12.4923 22.3692 12.5962L19.5038 19.3577C19.3602 19.6782 19.1198 19.9487 18.7827 20.1692C18.4455 20.3897 18.0948 20.5 17.7307 20.5ZM8.7115 19H17.7307C17.8012 19 17.8734 18.9808 17.9471 18.9423C18.0208 18.9039 18.0769 18.8398 18.1154 18.75L21 12V10.3078C21 10.218 20.9711 10.1443 20.9134 10.0866C20.8557 10.0289 20.782 10 20.6923 10H12.0961L13.35 4.51926L8.7115 9.13851V19ZM7.21153 8.50005V10H3.99998V19H7.21153V20.5H2.5V8.50005H7.21153Z'
                fill='#191919'
              />
            </svg>
          )}
        </button>
        <span className={styles.voteSep}></span>
        <button
          onClick={() => {
            if (!currentUser) {
              showSignInModal()
              return
            }
            imageGenDownvote(id)
            logInteraction({
              id: id,
              type: 'downvote',
              additionalData: imageGen.analytics,
            })
          }}
          className={styles.voteButton}
          data-set-vote={'downvote'}
          data-tooltip-id={'image-gen-tooltips'}
          data-tooltip-place={'top'}
          data-tooltip-html={
            'Downvote this image. <br/> The generator is improved based on your votes'
          }
          data-tooltip-hidden={!tooltips.showVoting}
        >
          {vote === 'downvote' ? (
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M6 3H16V16L9 23L7.75 21.75C7.63333 21.6333 7.5375 21.475 7.4625 21.275C7.3875 21.075 7.35 20.8833 7.35 20.7V20.35L8.45 16H3C2.46667 16 2 15.8 1.6 15.4C1.2 15 1 14.5333 1 14V12C1 11.8833 1.0125 11.7583 1.0375 11.625C1.0625 11.4917 1.1 11.3667 1.15 11.25L4.15 4.2C4.3 3.86667 4.55 3.58333 4.9 3.35C5.25 3.11667 5.61667 3 6 3ZM18 16V3H22V16H18Z'
                fill='#191919'
              />
            </svg>
          ) : (
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M6.2692 3.90393H16.7884V15.9039L10.1538 22.5L9.30768 21.6539C9.20384 21.55 9.11763 21.4125 9.04903 21.2414C8.98044 21.0702 8.94615 20.909 8.94615 20.7577V20.5039L10.0077 15.9039H3.30768C2.83203 15.9039 2.41183 15.7215 2.0471 15.3568C1.68237 14.992 1.5 14.5718 1.5 14.0962V12.4808C1.5 12.377 1.51346 12.2648 1.54038 12.1443C1.56729 12.0238 1.59742 11.9116 1.63075 11.8078L4.49612 5.04626C4.63972 4.72574 4.88011 4.45522 5.21727 4.23471C5.55446 4.01419 5.9051 3.90393 6.2692 3.90393ZM15.2884 5.40391H6.2692C6.19868 5.40391 6.12657 5.42314 6.05285 5.46161C5.97912 5.50007 5.92302 5.56418 5.88455 5.65393L2.99995 12.4039V14.0962C2.99995 14.1859 3.0288 14.2597 3.0865 14.3174C3.1442 14.3751 3.21793 14.4039 3.30768 14.4039H11.9038L10.65 19.8847L15.2884 15.2654V5.40391ZM16.7884 15.9039V14.4039H20V5.40391H16.7884V3.90393H21.4999V15.9039H16.7884Z'
                fill='#191919'
              />
            </svg>
          )}
        </button>
      </div>
    </>
  )
}
