import { create } from 'zustand'
import { Permissions } from '../context/ImageGenContext'

type ImageGeneratorImageData = {
  id: string
  asset_url: string
  asset_url_thumb: string
  token: string
  caption: string
}

export type ImageGeneratorRequestData = {
  id: string
  subject: string
  permissions: Permissions
  createdAt: string
  headerThumbnail: string | null
  headerThumbnailLink: string | null
  imageCount: number
  images: ImageGeneratorImageData[]
  orientation: string | null
  promptRewrite: boolean
  requestType: 'simple' | 'match_style'
  useCase: string | null
  status:
    | 'generating'
    | 'not_allowed'
    | 'usage_limit_daily'
    | 'usage_limit_concurrent'
    | 'usage_limit_total'
    | 'blocked'
    | 'creating'
}

interface ImageGenStoreState {
  config: {
    imageGenApiHostname?: string
    imageGenApiPath?: string
  }
  tooltips: {
    showVoting: boolean
    showReport: boolean
    showShare: boolean
    showUseStyle: boolean
    showEditImage: boolean
  }
  currentSearchConfig: Record<string, any>
  imageGens: Record<string, any>
  addImageGens: (data: Record<string, any>[]) => void
  upvote: (id: string) => void
  downvote: (id: string) => void
  report: (id: string) => void
  share: () => void
}

const useImageGenStore = create<ImageGenStoreState>((set) => ({
  config: {},
  tooltips: {
    showVoting: true,
    showReport: true,
    showShare: true,
    showUseStyle: true,
    showEditImage: true,
  },
  currentSearchConfig: {},
  imageGens: {},
  addImageGens: (data) =>
    set((state) => ({
      imageGens: {
        ...state.imageGens,
        ...data.reduce(
          (acc, imageGen) => ({
            ...acc,
            [imageGen.id]: {
              ...imageGen,
            },
          }),
          {},
        ),
      },
    })),
  upvote: (id) =>
    set((state) => ({
      tooltips: {
        ...state.tooltips,
        showVoting: false,
      },
      imageGens: {
        ...state.imageGens,
        [id]: {
          ...state.imageGens[id],
          vote: 'upvote',
        },
      },
    })),
  downvote: (id) =>
    set((state) => ({
      tooltips: {
        ...state.tooltips,
        showVoting: false,
      },
      imageGens: {
        ...state.imageGens,
        [id]: {
          ...state.imageGens[id],
          vote: 'downvote',
        },
      },
    })),
  report: (id) =>
    set((state) => ({
      tooltips: {
        ...state.tooltips,
        showReport: false,
      },
      imageGens: {
        ...state.imageGens,
        [id]: {
          ...state.imageGens[id],
          reported: !state.imageGens[id].reported,
        },
      },
    })),
  share: () =>
    set((state) => ({
      tooltips: {
        ...state.tooltips,
        showShare: false,
      },
    })),
}))

export default useImageGenStore
