import styles from './EditButtonBria.module.scss'
import { useCallback, useContext, useEffect, useState } from 'react'
import useImageGenStore from '../../lib/imageGenStore'
import { ImageGenContext } from '../../context/ImageGenContext'
import LoadingAnimation from '../LoadingAnimation'
import { genericEvent } from '../../../lib/events'
import useAuth from '../../lib/useAuth'

type BriaEventData = {
  type:
    | 'Bria_ImageSave'
    | 'Bria_ImageSavePSD'
    | 'Bria_ImageDownload'
    | 'Bria_ApplyChange'
    | 'Bria_IframePageNavigation'
    | 'Bria_CloseClicked'
  message?: {
    type?: string
    action?:
      | {
          key: string
          value: number
        }
      | string
  }
}

export default function EditButtonBria({
  id,
  mode = null,
}: {
  id: string
  mode?: string | null
}) {
  const {
    currentUser,
    showSubscribeModal,
    showEnterpriseModal,
    showSignInModal,
  } = useAuth()
  const { permissions } = useAuth()
  const { setDebugModalContent } = useContext(ImageGenContext)
  const imageGens = useImageGenStore((state) => state.imageGens)
  const imageGen = imageGens[id]
  const tooltips = useImageGenStore((state) => state.tooltips)
  const [loading, setLoading] = useState(true)
  const [showEditModal, setShowEditModal] = useState(false)

  const showEditModalCallback = useCallback(() => {
    if (!currentUser) {
      showSignInModal()
      return
    }

    if (!permissions.canEditImages) {
      if (currentUser.enterprise) {
        showEnterpriseModal()
      } else {
        showSubscribeModal()
      }
      return
    }

    setShowEditModal(true)

    const assetUrl = imageGen?.asset_url

    const iframeid = `b5e66435-2a03-470a-8605-ca768a3f7e03-1`
    const vhash = `8679a552aa79cc96`
    const title = 'Edit Image using AI'
    const sourceDomain = `https://${window.location.host}`

    // TODO: Use currentUser instead
    const analytics = JSON.parse(
      document.getElementsByTagName('body')[0].dataset.analytics || '{}',
    )
    const userId = analytics?.user_id

    const iframeUrl = `https://prod.embedded.bria.ai/?iframeId=${iframeid}&vhash=${vhash}&imageUrl=${assetUrl}&usageText=${title}&sourceDomain=${sourceDomain}&userId=${userId}`

    genericEvent({
      event: 'briaEditorOpen',
      data: imageGen.analytics,
    })

    useImageGenStore.setState({
      tooltips: {
        ...tooltips,
        showEditImage: false,
      },
    })

    setDebugModalContent(
      <div className={styles.editWrapper}>
        {loading && (
          <div className={styles.loading}>
            <LoadingAnimation />
          </div>
        )}
        <iframe
          onLoad={() => setLoading(false)}
          src={iframeUrl}
          className={styles.iframe}
        ></iframe>
      </div>,
    )
  }, [
    imageGen,
    loading,
    permissions.canEditImages,
    setDebugModalContent,
    tooltips,
    currentUser,
    showEnterpriseModal,
    showSignInModal,
    showSubscribeModal,
  ])

  const iframeCallback = useCallback(
    (event: MessageEvent) => {
      if (
        event.data &&
        event.data['type'] &&
        event.data['type'].startsWith('Bria')
      ) {
        console.log('Bria event')
        console.log(event.data)

        const { type, message } = event.data as BriaEventData

        const messageType = message?.type
        const messageAction = message?.action
        const messageActionKey =
          typeof messageAction === 'object' ? messageAction.key : undefined
        const messageActionValue =
          typeof messageAction === 'object' ? messageAction.value : undefined

        genericEvent({
          event: 'briaEditorInteraction',
          data: {
            type,
            messageType,
            messageActionKey,
            messageActionValue,
            analytics: imageGen.analytics,
          },
        })
      }
    },
    [imageGen],
  )

  useEffect(() => {
    if (!showEditModal) {
      window.removeEventListener('message', iframeCallback)
      return
    }

    window.addEventListener('message', iframeCallback)
    return () => {
      window.removeEventListener('message', iframeCallback)
    }
  }, [showEditModal, iframeCallback])

  return (
    <button
      onClick={() => {
        showEditModalCallback()
      }}
      className={`${styles.editButton} ${mode === 'popup' ? styles.popup : ''}`}
      data-tooltip-id={'image-gen-tooltips'}
      data-tooltip-place={'top'}
      data-tooltip-html={
        permissions.canEditImages
          ? ' Edit image, remove background, <br/>  resize,  upscale, and more...'
          : 'Please subscribe to edit images'
      }
      data-tooltip-hidden={!tooltips.showEditImage}
    >
      <span className={styles.label}>
        {mode === 'popup' ? 'Edit Image' : 'Edit'}
      </span>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M4.99997 19H6.2615L16.4981 8.7634L15.2366 7.50188L4.99997 17.7385V19ZM3.5 20.5V17.1154L16.6904 3.93078C16.8416 3.79343 17.0086 3.68729 17.1913 3.61237C17.374 3.53746 17.5656 3.5 17.7661 3.5C17.9666 3.5 18.1608 3.53558 18.3488 3.60675C18.5368 3.6779 18.7032 3.79103 18.848 3.94615L20.0692 5.18268C20.2243 5.32754 20.3349 5.49424 20.4009 5.68278C20.4669 5.87129 20.5 6.05981 20.5 6.24833C20.5 6.44941 20.4656 6.64131 20.3969 6.82403C20.3283 7.00676 20.219 7.17373 20.0692 7.32495L6.88458 20.5H3.5ZM15.8563 8.1437L15.2366 7.50188L16.4981 8.7634L15.8563 8.1437Z'
          fill='#191919'
        />
      </svg>
    </button>
  )
}
