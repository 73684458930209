import {
  SsoForm,
  en as messages,
  brandNeueTheme as theme,
} from '@envato/sso-forms-react'
import { useState } from 'react'
import styles from './SignInForm.module.scss'

function SignInForm({ returnPath }: { returnPath?: string }) {
  const [flow, setFlow] = useState<'signin' | 'signup'>('signin')
  return (
    <div className={styles.ssoFormVariables}>
      <div
        className={[styles.ssoFormWrapper, styles['color-scheme-light']].join(
          ' ',
        )}
      >
        <SsoForm
          languageCode='en'
          flow={flow}
          ssoHost='https://account.envato.test'
          messages={messages}
          clientSlug='labs'
          theme={theme}
          onSuccess={async (token: string) => {
            const response = await fetch(`/sso/callback?token=${token}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            })

            if (response.ok) {
              if (returnPath) {
                window.location.href = returnPath
              } else {
                window.location.reload()
              }
            } else {
              // TODO: handle error
              window.location.reload()
            }
          }}
          onClickSignUp={() => {
            setFlow('signup')
          }}
          onClickSignIn={() => {
            setFlow('signin')
          }}
          autoSignIn
        />
      </div>
    </div>
  )
}

export default SignInForm
