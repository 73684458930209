import { createContext } from 'react'

type ImageGenPrompt = {
  id: string
  slug: string
  name: string
  enabled: boolean
}

export type Permissions = {
  canGenerateImages: boolean
  canEditImages: boolean
  remainingImageGenerationQuota: number
  canViewDebug: boolean
  promptRewriteEnabled: boolean
}

export type Config = {
  imageGenApiHostname: string
  imageGenApiPath: string
  permissions: Permissions
  currentUser: {
    name: string
    email: string
    enterprise: boolean
  } | null
  imageGenPrompts: ImageGenPrompt[]
}

type ImageGenContextData = {
  modalContent: any
  setModalContent: (content: any) => void
  imageGenOverallMode: any
  setImageGenOverallMode: (mode: any) => void
  debugModalContent: any
  setDebugModalContent: (content: any) => void
  permissions: Config['permissions']
  setPermissions: (permission: Permissions) => void
  currentUser: Config['currentUser']
}

export const ImageGenContext = createContext<ImageGenContextData>({
  modalContent: null,
  setModalContent: () => {},
  imageGenOverallMode: null,
  setImageGenOverallMode: () => {},
  debugModalContent: null,
  setDebugModalContent: () => {},
  permissions: {
    canGenerateImages: false,
    canEditImages: false,
    remainingImageGenerationQuota: 0,
    canViewDebug: false,
    promptRewriteEnabled: false,
  },
  setPermissions: () => {},
  currentUser: null,
})
