import { createRoot } from 'react-dom/client'
import Router from './Router'

const dom = document.getElementById('js--image-gen-app')
if (dom) {
  const config = JSON.parse(dom.dataset.config)

  const root = createRoot(dom)
  root.render(<Router config={config} />)
}
