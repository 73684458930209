import { useEffect } from 'react'
import styles from './Generate.module.scss'
import useImageGenStore from './lib/imageGenStore'
import GeneratePrompt from './components/GeneratePrompt'
import { pageViewEvent } from '../lib/events'
import History from './components/History'
import useAuth from './lib/useAuth'
import { useNavigate } from 'react-router-dom'

const Generate = () => {
  const navigate = useNavigate()
  const { currentUser } = useAuth()

  useEffect(() => {
    if (!currentUser) {
      navigate('/image-gen')
    }
  }, [currentUser, navigate])

  useEffect(() => {
    pageViewEvent({ pageType: 'imageGenGenerate' })
  }, [])

  return (
    <main className={`${styles.wrapper} `}>
      <div className={styles.promptWrapper}>
        <GeneratePrompt />
      </div>

      <History />
    </main>
  )
}

export default Generate
