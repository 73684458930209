import { useCallback, useContext } from 'react'
import { ImageGenContext } from '../context/ImageGenContext'
import SignInForm from '../components/SignInForm'
import { useLocation } from 'react-router-dom'
import SubscribeForm from '../components/SubscribeForm'

export default function useAuth() {
  const { setModalContent, currentUser, permissions } =
    useContext(ImageGenContext)
  const location = useLocation()

  const showSignInModal = useCallback(
    (returnPath?: string) => {
      setModalContent(
        <SignInForm returnPath={returnPath || location.pathname} />,
      )
    },
    [setModalContent, location.pathname],
  )

  const showSubscribeModal = useCallback(() => {
    setModalContent(<SubscribeForm />)
  }, [setModalContent])

  const showEnterpriseModal = useCallback(() => {
    setModalContent(<div>Not available for enterprise users</div>)
  }, [setModalContent])

  return {
    showSignInModal,
    showEnterpriseModal,
    showSubscribeModal,
    currentUser,
    permissions,
  }
}
