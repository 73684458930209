import 'css-chunk:app/javascript/image_gen/components/Header.module.scss';export default {
  "globalNavWrapper": "_globalNavWrapper_1ihkz_1",
  "globalNavContent": "_globalNavContent_1ihkz_31",
  "globalNavLogo": "_globalNavLogo_1ihkz_41",
  "globalNavTitle": "_globalNavTitle_1ihkz_45",
  "globalNavBeta": "_globalNavBeta_1ihkz_68",
  "globalNavMenu": "_globalNavMenu_1ihkz_90",
  "globalNavMenuToggle": "_globalNavMenuToggle_1ihkz_95",
  "globalNavMenuPopout": "_globalNavMenuPopout_1ihkz_99",
  "globalNavMenuSection": "_globalNavMenuSection_1ihkz_134",
  "globalNavMenuName": "_globalNavMenuName_1ihkz_139",
  "globalNavMenuEmail": "_globalNavMenuEmail_1ihkz_147",
  "globalNavMenuLink": "_globalNavMenuLink_1ihkz_152"
};