import styles from './Curation.module.scss'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import Card from './components/Card'
import useImageGenStore from './lib/imageGenStore'
import { Config } from './context/ImageGenContext'

type GenerateResponseData = {
  status: string
  generations: ImageGeneratorData[]
}

type ImageGeneratorData = {
  id: string
  asset_url: string
  token: string
  caption: string
  debug: any
  curation: ImageGenCurationData
}

type ImageGenCurationData = {
  provider_slug: string
  provider_name: string
  image_gen_prompt_id: string
  internal_ranking: number
  enabled: boolean
  custom_prompt: string
}

type CurationResults = {
  curation: ImageGenCurationData
  generations: ImageGeneratorData[]
}

interface CurationProviderProps {
  providerSlug: string
  providerName: string
  enabled: boolean
  internalRanking: number
  customPrompt: string
  imageGenPromptId: string
  generations: ImageGeneratorData[]
  regenCallback: () => void
}

const CurationProvider = ({
  providerSlug,
  providerName,
  enabled,
  internalRanking,
  customPrompt,
  imageGenPromptId,
  generations,
  regenCallback,
}: CurationProviderProps) => {
  const [formEnabled, setFormEnabled] = useState(enabled)
  const [formInternalRanking, setFormInternalRanking] =
    useState(internalRanking)
  const [formCustomPrompt, setFormCustomPrompt] = useState(customPrompt)

  useEffect(() => {
    fetch('/api/v1/image_gen/curation_params', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        image_gen_prompt_id: imageGenPromptId,
        internal_ranking: formInternalRanking,
        enabled: formEnabled,
        custom_prompt: formCustomPrompt,
      }),
    })
  }, [formInternalRanking, formEnabled, formCustomPrompt])

  return (
    <div className={styles.row}>
      <div className={styles.debug}>
        Provider name: <strong>{providerName}</strong>
        <br />
        Slug: <strong>{providerSlug}</strong>
        <br />
        <br />
        <label>
          <input
            type='checkbox'
            checked={formEnabled}
            onChange={(e) => setFormEnabled(e.target.checked)}
          />{' '}
          Enabled
        </label>
        <br />
        <br />
        <label>
          Internal Ranking:{' '}
          <input
            type='text'
            value={formInternalRanking}
            size={3}
            onChange={(e) => setFormInternalRanking(e.target.value)}
          />{' '}
          <br />
          <small>(higher number is better)</small>
        </label>
        <br />
        <br />
        <label>
          Custom Prompt for this model: <br />
          <small>(leave blank to use default prompt above)</small> <br />
          <input
            type='text'
            value={formCustomPrompt}
            onChange={(e) => setFormCustomPrompt(e.target.value)}
          />{' '}
        </label>
        <br />
        <br />
        <small>ID: {imageGenPromptId}</small>
        <br />
        <br />
        <button onClick={regenCallback} className={styles.save}>
          Generate More
        </button>
      </div>
      <div className={styles.images}>
        {generations.map((generation) => (
          <div key={generation.id} className={styles.image}>
            Subject: <strong>{generation.debug.request.subject}</strong> <br />
            Prompt: <strong>{generation.debug.provider.prompt}</strong> <br />
            Options:{' '}
            <strong>
              {JSON.stringify(generation.debug.provider.options)}
            </strong>{' '}
            <br />
            <Card
              key={generation.id}
              id={generation.id}
              assetUrl={generation.asset_url}
              assetUrlThumb={generation.asset_url_thumb}
              token={generation.token}
              debug={generation.debug}
              caption={generation.caption}
              renderCallback={() => {}}
              allowPopup={false}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

type imageGenPrompt = {
  id: string
  slug: string
  name: string
  enabled: boolean
}

type CurationProps = {
  config: Config
}

const Curation = ({ config }: CurationProps) => {
  const { id } = useParams()
  const [subject, setSubject] = useState('cat')
  const [imageGenResults, setImageGenResults] = useState<CurationResults[]>([])
  const addImageGens = useImageGenStore((state) => state.addImageGens)
  const [providersToCuration, setProvidersToCuration] = useState<
    imageGenPrompt[]
  >(config.imageGenPrompts)

  useEffect(() => {
    useImageGenStore.setState({ config: config })
  }, [])

  const goGoGo = useCallback(
    async (customSubject, customPromptId) => {
      // lets generate some stuff.
      try {
        const response = await fetch('/api/v1/image_gen/curation', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            style_id: id,
            subject: customSubject,
            image_gen_prompt_id: customPromptId,
            image_gen_prompts: providersToCuration,
          }),
        })

        const data = (await response.json()) as GenerateResponseData
        addImageGens(data.generations)

        setImageGenResults((prev) => {
          const newData = [...prev]
          data.generations.forEach((result) => {
            // check if this entry is in the existing state data yet?
            const existsIn = newData.findIndex(
              (prevItem) =>
                prevItem.curation.image_gen_prompt_id ===
                result.curation.image_gen_prompt_id,
            )
            if (existsIn >= 0) {
              newData[existsIn].generations.unshift(result)
            } else {
              newData.push({
                curation: result.curation,
                generations: [result],
              })
            }
          })
          return newData
        })
      } catch (e) {
        console.error(e)
      }
    },
    [providersToCuration],
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles.providers}>
        {providersToCuration.map((provider) => (
          <label key={provider.id} className={styles.provider}>
            {provider.name}
            <input
              type='checkbox'
              checked={provider.enabled}
              onChange={(e) => {
                const newProviders = providersToCuration.map((p) => {
                  if (p.id === provider.id) {
                    return {
                      ...p,
                      enabled: e.target.checked,
                    }
                  }
                  return p
                })
                setProvidersToCuration(newProviders)
              }}
            />
          </label>
        ))}
      </div>
      <div className={styles.input}>
        {[
          'cat',
          'house',
          'truck',
          'eagle in the jungle',
          'a mysterious and magical forest with a monster',
          'coffee',
          'man doing meditation under tree',
          'bonsai tree',
          'panda on a beach',
        ].map((term) => (
          <button key={term} onClick={() => goGoGo(term)}>
            {term}
          </button>
        ))}
        <input
          type={'text'}
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <button onClick={() => goGoGo(subject)}>generate</button>
      </div>
      <div className={styles.main}>
        {imageGenResults.map((imageGenerator) => (
          <CurationProvider
            key={imageGenerator.curation.image_gen_prompt_id}
            imageGenPromptId={imageGenerator.curation.image_gen_prompt_id}
            enabled={imageGenerator.curation.enabled}
            providerSlug={imageGenerator.curation.provider_slug}
            providerName={imageGenerator.curation.provider_name}
            internalRanking={imageGenerator.curation.internal_ranking}
            customPrompt={imageGenerator.curation.custom_prompt}
            generations={imageGenerator.generations}
            regenCallback={() => {
              goGoGo(subject, imageGenerator.curation.image_gen_prompt_id)
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default Curation
