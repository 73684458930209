import styles from './LoadingAnimation.module.scss'

const LoadingAnimation = ({ size = 80, border = 5 }) => {
  const style = {
    '--loading-spinner-size': `${size}px`,
    '--loading-spinner-border': `${border}px`,
  } as React.CSSProperties
  return (
    <div className={styles.container} style={style}>
      <div className={styles.spinner}></div>
    </div>
  )
}

export default LoadingAnimation
