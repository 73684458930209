import { useContext, useEffect, useState } from 'react'
import CardPublished from './CardPublished'
import GridWrapper from './GridWrapper'
import { ImageGenContext } from '../context/ImageGenContext'
import useImageGenStore from '../lib/imageGenStore'
import GridHomeWrapper from './GridHomeWrapper'

type GenerateResponseData = {
  status: string
  generations: ImageGeneratorData[]
}

type ImageGeneratorData = {
  id: string
  asset_url: string
  asset_url_thumb: string
  token: string
  caption: string
  debug: string
}

export default function ImageGenPublished() {
  const [imageGenerators, setImageGenerators] = useState<ImageGeneratorData[]>(
    [],
  )
  const addImageGens = useImageGenStore((state) => state.addImageGens)

  useEffect(() => {
    const fetchPublishedAssets = async () => {
      // when we first load we want to fetch all the published images and popupate our list of thingeys.
      try {
        const response = await fetch('/api/v1/image_gen/published', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            please: 'and thank you',
          }),
        })

        const data = (await response.json()) as GenerateResponseData

        addImageGens(data.generations)
        setImageGenerators(data.generations)
      } catch (e) {}
    }
    fetchPublishedAssets()
  }, [])

  if (!imageGenerators.length) {
    return null
  }

  return (
    <GridHomeWrapper>
      {imageGenerators.map((imageGenerator) => {
        return (
          <CardPublished
            key={imageGenerator.id}
            id={imageGenerator.id}
            assetUrl={imageGenerator.asset_url}
            assetUrlThumb={imageGenerator.asset_url_thumb}
            caption={imageGenerator.caption}
            debug={imageGenerator.debug}
          />
        )
      })}
    </GridHomeWrapper>
  )
}
