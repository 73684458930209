import 'css-chunk:app/javascript/image_gen/components/GeneratePrompt.module.scss';export default {
  "wrapper": "_wrapper_u002o_1",
  "border": "_border_u002o_15",
  "highlightNotice": "_highlightNotice_u002o_36",
  "hasFocus": "_hasFocus_u002o_39",
  "promptInput": "_promptInput_u002o_60",
  "promptButton": "_promptButton_u002o_114",
  "promptLabel": "_promptLabel_u002o_140",
  "promptAlert": "_promptAlert_u002o_149",
  "promptStyle": "_promptStyle_u002o_175",
  "promptStyleThumb": "_promptStyleThumb_u002o_180",
  "promptStyleClear": "_promptStyleClear_u002o_186",
  "smartSuggestionWrapper": "_smartSuggestionWrapper_u002o_195",
  "smartSuggestionIcon": "_smartSuggestionIcon_u002o_207",
  "smartSuggestionTitle": "_smartSuggestionTitle_u002o_211",
  "smartSuggestionContent": "_smartSuggestionContent_u002o_223",
  "remainingQuotaWrapper": "_remainingQuotaWrapper_u002o_229"
};