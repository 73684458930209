import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Curation from './Curation'
import { Config, ImageGenContext } from './context/ImageGenContext'
import { useState } from 'react'
import ImageGenWrapper from './ImageGenWrapper'
import ScrollToTop from './components/ScrollToTop'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

export default function AppRouter({ config }: { config: Config }) {
  const [modalContent, setModalContent] = useState(null)
  const [debugModalContent, setDebugModalContent] = useState(null)
  const [imageGenOverallMode, setImageGenOverallMode] = useState(null)
  const [permissions, setPermissions] = useState(config.permissions)

  return (
    <ImageGenContext.Provider
      value={{
        modalContent: modalContent,
        setModalContent: setModalContent,
        imageGenOverallMode: imageGenOverallMode,
        setImageGenOverallMode: setImageGenOverallMode,
        debugModalContent: debugModalContent,
        setDebugModalContent: setDebugModalContent,
        permissions: permissions,
        setPermissions: setPermissions,
        currentUser: config.currentUser,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route
              path='/image-gen'
              element={<ImageGenWrapper config={config} mode={'home'} />}
            />
            <Route
              path='/image-gen/generate'
              element={<ImageGenWrapper config={config} mode={'generate'} />}
            />
            <Route
              path='/image-gen/favorites'
              element={<ImageGenWrapper config={config} mode={'favorites'} />}
            />
            <Route
              path='/image-gen/:imageGenId'
              element={<ImageGenWrapper config={config} mode={'single'} />}
            />
            <Route
              path='/admin/image_gen_styles/:id'
              element={<Curation config={config} />}
            />
          </Routes>
        </Router>
      </QueryClientProvider>
    </ImageGenContext.Provider>
  )
}
